body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

select.form-control,
textarea.form-control,
input.form-control {
  font-size: 16px;
}
input[type=file] {
  width: 100%;
}
.App .navbar-brand {
  font-weight: bold;
}
.Home .lander {
    padding: 80px 0;
    text-align: center;
}

.Home .lander h1 {
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
}

.Home .lander p {
    color: #999;
}

img {
    width: 100%;
    max-width: 514px;
}

.navbar-default { 
    background: white;
    border: 0;
}
.LoaderButton .spinning.glyphicon {
    margin-right: 7px;
    top: 2px;
    -webkit-animation: spin 1s infinite linear;
            animation: spin 1s infinite linear;
  }
  @-webkit-keyframes spin {
    from { -webkit-transform: scale(1) rotate(0deg); transform: scale(1) rotate(0deg); }
    to { -webkit-transform: scale(1) rotate(360deg); transform: scale(1) rotate(360deg); }
  }
  @keyframes spin {
    from { -webkit-transform: scale(1) rotate(0deg); transform: scale(1) rotate(0deg); }
    to { -webkit-transform: scale(1) rotate(360deg); transform: scale(1) rotate(360deg); }
  }
@media all and (min-width: 480px) {
    .Login {
      padding: 60px 0;
    }
  
    .Login form {
      margin: 0 auto;
      max-width: 320px;
    }
  }
@media all and (min-width: 480px) {
    .Signup {
      padding: 60px 0;
    }
  
    .Signup form {
      margin: 0 auto;
      max-width: 320px;
    }
  }
  
  .Signup form span.help-block {
    font-size: 14px;
    padding-bottom: 10px;
    color: #999;
  }
