.Home .lander {
    padding: 80px 0;
    text-align: center;
}

.Home .lander h1 {
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
}

.Home .lander p {
    color: #999;
}

img {
    width: 100%;
    max-width: 514px;
}

.navbar-default { 
    background: white;
    border: 0;
}